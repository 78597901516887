import React, { useState, useEffect } from 'react';
import axios from "axios";
import { jwtDecode } from 'jwt-decode';
import { useHistory,Link } from 'react-router-dom';

const Dashboard = () => {
  const hostApi = "https://apisls.lifeforcode.net";
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const [users, setUsers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    refreshToken();
    // getUsers();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${hostApi}/token`);
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  }

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date();
    if (expired * 1000 < currentDate.getTime()) {
      try {
        const response = await axios.get(`${hostApi}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);

        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      } catch (error) {
        console.error('Error refreshing token:', error);
        history.push("/");
      }
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  const getUsers = async () => {
    try {
      const response = await axiosJWT.get(`${hostApi}/users`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }


  return (
    <div className="container mt-5">
      <h1 className='has-text-bold'>Welcome Back: {name}</h1>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/dashboard">Home</Link></li>
          <li className="is-active"><a href="#" aria-current="page">Dashboard</a></li>
        </ul>
      </nav>
      <button onClick={getUsers} className='button is-info'>
        Get Users
      </button>
      <div style={{ padding: '10px 0' }}>
        <Link to="/contactsales" className='button is-primary'>Contact Sales</Link> {/* Tombol menuju halaman Contact Sales */}
      </div>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Dashboard;
