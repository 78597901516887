import React, { useState, useEffect } from 'react';
import axios from "axios";
import { jwtDecode } from 'jwt-decode';
import { useHistory,Link } from 'react-router-dom';
import { FaWhatsapp, FaSearch } from 'react-icons/fa';

const ContactSales = () => {
  const hostApi = "https://apisls.lifeforcode.net";
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const [contacts, setContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [contactsPerPage] = useState(5); // Jumlah kontak per halaman
  const history = useHistory();

  useEffect(() => {
    refreshToken();
    getContacts();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${hostApi}/token`);
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  }

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date();
    if (expired * 1000 < currentDate.getTime()) {
      try {
        const response = await axios.get(`${hostApi}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);

        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      } catch (error) {
        console.error('Error refreshing token:', error);
        history.push("/");
      }
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  const getContacts = async () => {
    try {
      const response = await axiosJWT.get(`${hostApi}/contactms`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setContacts(response.data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  }

  // Menghitung indeks kontak pertama dan terakhir pada setiap halaman
  const indexOfLastContact = currentPage * contactsPerPage;
  const indexOfFirstContact = indexOfLastContact - contactsPerPage;

  // Mencari kontak berdasarkan nama sales
  const filteredContacts = contacts.filter(contact =>
    contact.sales.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Mengubah halaman
  const paginate = pageNumber => setCurrentPage(pageNumber);

  // Menghitung jumlah halaman
const pageNumbers = [];
for (let i = 1; i <= Math.ceil(filteredContacts.length / contactsPerPage); i++) {
  pageNumbers.push(i);
}

// Membuat fungsi untuk menampilkan 3 tombol nomor halaman
const renderPageNumbers = pageNumbers.map(number => {
  if (number >= currentPage - 1 && number <= currentPage + 1) {
    return (
      <li key={number}>
        <button
          className={currentPage === number ? "pagination-link is-current" : "pagination-link"}
          onClick={() => paginate(number)}
        >
          {number}
        </button>
      </li>
    );
  } else {
    return null;
  }
});


  return (
    <div className="container mt-5">
    <h1 className='has-text-bold'>Welcome Back: {name}</h1>
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><Link to="/dashboard">Home</Link></li>
        <li className="is-active"><a href="#" aria-current="page">Contact Sales</a></li>
      </ul>
    </nav>
    <div className="field has-addons">
      <div className="control is-expanded">
        <input
          className="input"
          type="text"
          placeholder="Search by sales name..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="control">
        <button className="button is-info" onClick={() => setCurrentPage(1)}>
          <FaSearch />
        </button>
      </div>
    </div>
    <table className="table is-striped is-fullwidth">
      <thead>
        <tr>
          <th>Nama Sales</th>
          <th>Divisi</th>
          <th>MS</th>
          <th>Area</th>
          <th>Kontak MS</th>
        </tr>
      </thead>
      <tbody>
        {filteredContacts.slice(indexOfFirstContact, indexOfLastContact).map(contact => (
          <tr key={contact.id}>
            <td>{contact.sales}</td>
            <td>{contact.divisi}</td>
            <td>{contact.ms}</td>
            <td>{contact.area}</td>
            <td ><a href={contact.kontakms}><FaWhatsapp /></a></td>
          </tr>
        ))}
      </tbody>
    </table>
    <nav className="pagination is-centered" role="navigation" aria-label="pagination">
      <button className="pagination-previous" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
      <ul className="pagination-list">
        {renderPageNumbers}
      </ul>
      <button className="pagination-next" onClick={() => paginate(currentPage + 1)} disabled={indexOfLastContact >= filteredContacts.length}>Next</button>
    </nav>
  </div>
  );
};

export default ContactSales;
