import React, { useState } from 'react';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Login = () => {
  const hostApi = "https://apisls.lifeforcode.net";
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const [msg, setMsg] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const Auth = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${hostApi}/login`, {
        email: email,
        password: password
      });
      history.push("/dashboard");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  }

  return (
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-4-desktop">
              <form onSubmit={Auth} className="box">
                <p className='has-text-centered'>{msg}</p>
                <div className="field">
                  <label className="label">Email or Username</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Email or Username"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Password</label>
                  <div className="control has-icons-right">
                    <input
                      className="input"
                      type={showPassword ? "text" : "password"}
                      placeholder="*******"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="icon is-right" style={{ pointerEvents: 'auto' }} onClick={togglePasswordVisibility}>
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </span>
                  </div>
                </div>
                <div className="field">
                  <div className="control has-text-centered">
                    <button className="button is-primary " type="submit">Login</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
